import * as React from "react"
import { graphql } from "gatsby"
import Moment from "moment"
import Seo from "../components/seo"

const IndexPage = ({ data }) => {
  const press = data.press.nodes

  return (
    <div className="bg-gray-300 dark:bg-zinc-900 pt-12">
      <Seo
        title="Press"
        description="Recent news coverage on Budmen Industries 💭"
      />
      {/* pressCoverage */}
      <section className="px-2 pb-24 mx-auto  bg-gray-300 dark:bg-zinc-900 mb-0  container">
        <h1 className="sm:text-3xl mx-auto text-2xl font-bold text-gray-800 museo text-center dark:text-zinc-200 mb-0">
          BUDMEN BULLETIN
        </h1>
        <p className="text-xs font-light tracking-widest mx-auto text-zinc-700 text-center dark:text-zinc-400 mb-4">
          PRESS COVERAGE FROM AROUND THE WORLD WIDE WEB
        </p>
        <div className="w-full mx-auto gap-4 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1">
          {press.map((pressCoverage, index) => (
            <a
              href={pressCoverage.url}
              className="text-gray-800 dark:text-zinc-300 border border-gray-400 dark:border-zinc-800 w-full hover:opacity-70"
              rel="noreferrer"
              target="_blank"
              key={index}
            >
              <div className="w-full h-full bg-gray-300 border-gray-400 dark:bg-zinc-800 mb-4">
                <div className="p-2">
                  <span className="rounded-full text-gray-300 dark:text-zinc-800 text-[0.5rem] px-1 bg-gray-400">
                    Press
                  </span>
                  <h3
                    className={
                      (index === 1 && "text-lg hwt tracking-wide") ||
                      (index % 2 === 0 && "font-bold text-lg museo") ||
                      (index % 5 === 0 && "text-lg hwt tracking-wide") ||
                      (index % 3 === 0 && "font-bold text-lg industryBase") ||
                      "text-lg font-bold"
                    }
                  >
                    {pressCoverage.title}
                  </h3>
                  <div className="flex relative border-t border-b border-gray-400 p-1 my-2 dark:border-zinc-500 justify-between items-center mb-2">
                    <span className="text-[0.6rem] text-gray-800 italic dark:text-zinc-400">
                      {pressCoverage.source}
                    </span>
                    <span className="text-[0.6rem] text-gray-800 dark:text-zinc-400">
                      {Moment(pressCoverage.date).format("MMMM D, YYYY")}
                    </span>
                  </div>
                  <p className="text-xs mt-2">{pressCoverage.excerpt}</p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </section>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query PressQuery {
    press: allSanityPress(sort: { fields: [date], order: DESC }) {
      nodes {
        title
        source
        url
        excerpt
        date
      }
    }
  }
`
